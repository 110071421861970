import { Box, Container, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Btn from "../components/Btn";
import ConcertSchedule from "../components/ConcertSchedule";
import ContactBanner from "../components/ContactBanner";
import HeroSection from "../components/HeroSection";
import Layout from "../components/Layout/Layout";
import GImage from "../components/GImage";
import { baseTheme } from "../theme";

const IndexPage = () => {
  return (
    <Layout title='Home' colorMode='light'>
      <>
        <Box bg='brand.pearl' w='100%'>
          <HeroSection alt='Band Majanko vor einer Hafenmauer' bgColor='brand.sand' justifyContent={{ xl: "center", base: "end" }} minH={{ md: "90vh", base: "100vh" }} maxH={{ md: "90vh", base: "100vh" }} video={false} mediaOpacity={1} mediaSrc='majanko_wall.jpg' parallaxOffsetEnd={400} parallaxOffsetStart={0}>
            <Container variant='layoutContainer' mb={{ xl: 0, base: 8 }}>
              <Box py={12} px={{ xl: 12, base: 4 }} bg='brand.pearl' maxW='700px'>
                <Text textStyle='h5' fontWeight='bold' fontFamily={"Inter"} color='brand.space' textAlign='center'>
                  Musikvideo
                </Text>
                <Heading textStyle={"h00"} textAlign='center' fontFamily='EBGaramond' fontWeight='bold' mb={8} color='brand.space'>
                  Die Nacht ist noch jung
                </Heading>
                <Btn target='_blank' rel='noopener' isExternal={true} link='https://www.youtube.com/watch?v=1Rtp6r6Zib4' textColor={"brand.pearl"} mx='auto' width='fit-content' borderColor={"brand.primary"} bg='brand.primary' text='Jetzt ansehen' arrowColor={baseTheme.colors.brand.pearl}></Btn>
              </Box>
            </Container>
          </HeroSection>
        </Box>
        <Box bg='brand.pearl' position='relative' zIndex='3' marginTop={-1}>
          <Box px={{ md: 12, base: 0 }} py={16} position='relative' overflow='hidden' bg='brand.space'>
            <Box position='relative' zIndex='1'>
              <Text textStyle='h5' fontWeight='bold' fontFamily={"Inter"} color='brand.pearl' textAlign='center'>
                Höre jetzt die neue Single.
              </Text>
              <Heading textStyle={"h00"} textAlign='center' fontFamily='EBGaramond' mb={8} color='brand.pearl'>
                Planet Blau
              </Heading>
              <Box display={"flex"} flexDirection={{ md: "row", base: "column" }} gap={4} justifyContent='center'>
                <Btn target='_blank' rel='noopener' isExternal={true} link='https://www.youtube.com/watch?v=SjOMrwxw3eQ&ab_channel=Majanko' textColor={"brand.pearl"} mx='auto' width='fit-content' borderColor={"brand.primary"} bg='brand.primary' text='Musikvideo' arrowColor={baseTheme.colors.brand.pearl}></Btn>
                <Btn target='_blank' rel='noopener' isExternal={true} link='https://open.spotify.com/track/4MgfiC8QxfAB98obZQiR3z?si=tSQcUh22S8ysSpeDhhxY9Q' textColor={"brand.primary"} mx='auto' width='fit-content' borderColor={"brand.pearl"} bg='brand.pearl' text='Anhören'></Btn>
              </Box>
            </Box>
            <GImage position='absolute' left='0' top='50%' transform='translateY(-50%)' mx='auto' my={16} alt='Majanko logo' src={"planet_neptun.png"} zIndex='0' />
          </Box>
          <Box py={16} px={{ md: 12, base: 0 }} position='relative' overflow='hidden' bg='brand.sand'>
            <Box position='relative' zIndex='1'>
              <Text textStyle='h5' fontWeight='bold' fontFamily={"Inter"} color='brand.space' textAlign='center'>
                Lyrics by Theda Ostermann.
              </Text>
              <Heading textStyle={"h00"} textAlign='center' fontFamily='EBGaramond' mb={8} color='brand.space'>
                Procrastination
              </Heading>
              <Text maxW='700' mx='auto' textStyle='h5' px={{ sm: 8, base: 4 }} mb={16} fontWeight='regular' fontFamily={"Inter"} color='brand.space' textAlign='center'>
                Beim Schreiben lassen wir uns bei Zeiten von Poet:innen in unserem Umfeld inspirieren und unterstützen. Theda Ostermann und Andreas Schmidt sorgen mit ihren Worten für frischen Wind in unseren Texten.
              </Text>
              <Box display='flex' gap={4} px={12} boxSizing='border-box' mx='auto' justifyContent={"center"} flexWrap='wrap'>
                <Btn target='_blank' rel='noopener' isExternal={true} link='https://open.spotify.com/intl-de/track/27kSks62pTSsxb3OUgZSWY?si=6f8c2d14c1d84f92' textColor={"brand.space"} mx='auto' width='fit-content' borderColor={"brand.pearl"} arrowColor={baseTheme.colors.brand.space} bg='brand.pearl' text='Anhören'></Btn>
              </Box>
            </Box>
          </Box>

          {/* <Container
            variant="layoutContainer"
          >
            <Box
            position='relative'
            pb='56.25%'
            >
          <iframe 
            style={{
              width: '100%', 
              height: '100%', 
              position:'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0

            }} 
            src="https://www.youtube.com/embed/bYu2DEkiDwQ" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
          ></iframe>
          </Box>
          </Container> */}

          <ConcertSchedule />

          <Box bg='brand.gray'>
            <ContactBanner
              topline='Kontakt'
              heading='Ein Stück Weg miteinander gehen.'
              text='Sei es eine Anfrage für einen Auftritt oder Fanpost - wir freuen uns immer auf Nachrichten! Kontaktiere uns gerne über unsere Social-Media-Kanäle oder schicke uns Elektropost.'
              color='brand.space'
              btnText='E-Mail'
              btnBgColor='transparent'
              btnBorderColor={"brand.primary"}
              btnTextColor='brand.primary'
              iconBtnBgColor={"transparent"}
              iconBtnBorderColor={"brand.space"}
              iconBtnColor=''
              iconBtnSrc1='icon_insta.svg'
              iconBtnSrc2='icon_fb.svg'
              isExternal1={true}
              link1='mailto:majanko.music@gmail.com'
              isExternal2={true}
              link2='/https://instagram.com/majanko.music?igshid=YmMyMTA2M2Y='
              isExternal3={true}
              link3='/https://www.facebook.com/majanko.music'
            />
          </Box>
        </Box>
      </>
    </Layout>
  );
};

export default IndexPage;
