import React, { useEffect } from "react";
import { Container, Text, Box, useColorMode } from "@chakra-ui/react";
import Text3Col from "../components/Text3Col";
import HeadingSubline from "../components/Heading+Subline";
import GImage from "../components/GImage";
import HeroSection from "../components/HeroSection";
import Layout from "../components/Layout/Layout";
import ContactBanner from "../components/ContactBanner";
import Feature2Col from "../components/Feature2Col";
import Members from "../components/Members";

const About = () => {
  return (
    <Layout title='Über uns' colorMode='dark'>
      <>
        <Box bg='brand.space' w='100%'>
          <Container variant='layoutContainer' pt={{ sm: 24, base: 20 }} px={{ sm: 8, base: 4 }}>
            <HeroSection alt='Band Majanko vor einer Hafenmauer' bgColor='brand.sand' justifyContent='center' minH={{ md: "650px", base: "400px" }} maxH={{ md: "650px", base: "400px" }} video={false} mediaOpacity={1} mediaSrc='space_bg_gradient.jpg' parallaxOffsetEnd={200} parallaxOffsetStart={-200}>
              <Box display='grid' h='100%'>
                <GImage alignSelf='center' gridRow='1' gridColumn='1' src='planet_neptun.png' w='300px' h='300px' mx='auto' objectFit='cover' zIndex={2} />
                <Box gridRow='1' gridColumn='1' alignSelf='end' h='400px' bg='red' zIndex={1} background={"linear-gradient(0deg, rgba(0,0,17,1) 0%, rgba(255,255,255,0) 100%)"}></Box>
              </Box>
            </HeroSection>
          </Container>
          <Box py={16}>
            <HeadingSubline color={"brand.pearl"} fontHeading='Inter' fontSubline={"EBGaramond"} heading='Machen, was einen bewegt.' subline={"Unsere Reise"} showLine={true} />
          </Box>
          <Container variant='layoutContainer'>
            {/* <GImage alt='Band Majanko' src='majanko_band.jpg' css={{'img': {objectFit: 'contain !important', objectPosition:'top'}}} width={{md: '50%', base:'100%'}}/> */}
            <Box mx='auto' maxWidth={700}>
              <Text fontWeight='bold' color='brand.pearl' maxW='4xl' mx='auto' mb={8}>
                Angefangen als Duo, entstand nach und nach eine größere Band, bestehend aus Majanko Bauer (Gesang und Gitarre), Andreas Ernst (E-Gitarre), Luna Hilbers (Saxofon), Fiona Helbing (Violine), Steffen Andreesen (Bass) und Thorsten Prinz (Schlagzeug). Unsere langen Abende im Proberaum, unsere jährlichen Musikreisen auf Wangerooge und unsere Freundschaft verbindet und motiviert uns. In diesem Jahr haben wir endlich unsere erste gemeinsame EP aufgenommen und hoffen nun auf einen Support für professionelle und erfolgreiche Veröffentlichungen. Langfristig ist unser Ziel eine
                ganzheitliche Professionalisierung in der Musikbranche.
              </Text>
              <Text color='brand.pearl' maxW='4xl' mx='auto' mb={8}>
                Von unserer Heimat an der Nordsee inspiriert, haben wir uns als Band gefunden, um mit unseren wellenartigen Kompositionen zu überzeugen. Dabei tauchen wir von einer tiefen Ruhe in eine Klangwelt ein, die den Zuhörer mit auf eine emotionale und atmosphärische Reise nimmt. Unsere Musik ist belebend, leidenschaftlich und vor allem authentisch.
              </Text>
              <Text color='brand.pearl' maxW='4xl' mx='auto'>
                Die Texte spiegeln die Leinwand des Lebens: Von den Zeiten, in denen sich die Dinge anfühlen, als wären sie in einem Kreislauf gefangen, aber auch davon, aus diesem Kreis auszubrechen. Es sind Texte der Resignation, und der Hoffnung. Von einer Schwere, die sich in Leichtigkeit auflöst. Sie eröffnen neue Blickwinkel, und bieten dabei dem Zuhörer seinen ganz eigenen Interpretationsspielraum.
              </Text>
            </Box>
          </Container>

          <GImage mx='auto' my={16} alt='Majanko logo' src={"majanko_logo_signet_white.svg"} />

          {/* <Members /> */}

          <Box bg='brand.space'>
            <ContactBanner
              topline='Kontakt'
              heading='Ein Stück Weg miteinander gehen.'
              text='Sei es eine Anfrage für einen Auftritt oder Fanpost - wir freuen uns immer auf Nachrichten! Kontaktiere uns gerne über unsere Social-Media-Kanäle oder schicke uns Elektropost.'
              color='brand.pearl'
              btnText='E-Mail'
              btnBgColor='transparent'
              btnBorderColor={"brand.pearl"}
              btnTextColor='brand.pearl'
              iconBtnBgColor={"transparent"}
              iconBtnBorderColor={"brand.pearl"}
              iconBtnColor=''
              iconBtnSrc1='icon_insta_white.svg'
              iconBtnSrc2='icon_fb_white.svg'
              isExternal1={true}
              link1='mailto:majanko.music@gmail.com'
              isExternal2={true}
              link2='/https://instagram.com/majanko.music?igshid=YmMyMTA2M2Y='
              isExternal3={true}
              link3='/https://www.facebook.com/majanko.music'
            />
          </Box>
        </Box>
      </>
    </Layout>
  );
};

export default About;
