import { Flex, Box, Text } from "@chakra-ui/react";
import React from "react";
import Btn from "./Btn";

export default function ScheduleEntry({ date, city, link, isExternal, showLink }) {
  return (
    <>
      <Flex w='100%' justifyContent={"space-between"} px={{ md: 8, base: 4 }} flexDirection={{ md: "row", base: "column" }}>
        <Box border={{ md: "none", base: "1px solid black" }} p={{ md: 0, base: 8 }} mb={{ md: 0, base: "-1px" }}>
          <Flex gap={{ md: 12, base: 4 }} flexDirection={{ sm: "row", base: "column" }} alignItems='baseline'>
            <Text minWidth={{ md: 200, base: "auto" }} color='brand.primary' as='h3' fontSize={{ lg: "42px", md: "36px", base: "28px" }} lineHeight={{ lg: "48px", base: "48px" }} fontFamily='Inter' fontWeight='bold'>
              {date}
            </Text>
            <Text as='h4' textStyle='pBig' fontFamily='Inter' fontWeight='bold'>
              {city}
            </Text>
          </Flex>
        </Box>
        {showLink && <Btn link={link} isExternal={isExternal} textColor='brand.primary' mb='-1px' text='Tickets' fontFamily='Inter' fontWeight='bold' borderColor='brand.space' bg={"rgba(255, 255, 255, 0.1)"} />}
      </Flex>
      <Box maxWidth={800} w='calc(100% - 2rem)' h='1px' bg={{ md: "brand.space", base: "transparent" }} mr={{ md: 8, base: 16 }} mb={{ md: 12, base: 4 }}></Box>
    </>
  );
}
