import React from "react";
import { Box, Container, Heading, Text, Flex } from "@chakra-ui/react";
import GImage from "./GImage";
import Btn from "./Btn";

const EPStage = ({}) => {
  return (
    <Container variant='layoutContainer' display={{ lg: "flex", base: "block" }} pt={{ sm: 24, base: 20 }} px={{ sm: 8, base: 4 }}>
      <GImage src={"ep_cover_clean.jpg"} alt='Vorderseite EP "Bis der Faden reißt"' css={{ img: { objectFit: "contain !important", objectPosition: "center" } }} w='fit-content' maxH='600px' width={{ lg: "50%", base: "100%" }} />
      <Box width={{ lg: "50%", base: "100%" }} px={8} py={24} border='1px solid' maxWidth='3xl'>
        <Text textStyle='h4' fontFamily='Inter' color='brand.primary'>
          EP out now
        </Text>
        <Text textStyle='h1' fontFamily={"EBGaramond"} color='brand.pearl' mb={8}>
          Bis der Faden reißt.
        </Text>
        <Text textStyle='p' color='brand.pearl' mb={20}>
          Viel ist passiert im letzten Jahr und wir haben uns als Band neu gefunden. Den Grundstein dieser Verändeurng symbolisiert unsere neue EP "Bis der Faden reißt". In die Scheibe floss viel Herzblut von jedem von uns und war für uns eine großartige Erfahrung. Wir freuen uns darauf, euch die Songs beim nächsten Konzert Live präsentieren zu dürfen. Wenn ihr bis dahin nicht abwarten könnt, haben wir auch gleichzeitig ein Video produziert.
        </Text>
        <Flex flexWrap='wrap' gap={4}>
          <Btn isExternal={true} link='https://open.spotify.com/album/1EJBoFI0hTgnGdv2wz0qfy?si=sljGiXvzQgWwgneO8yiIug' textColor={"white"} width='fit-content' borderColor={"brand.primary"} bg='brand.primary' text='Jetzt hören' css={{ path: { stroke: "#ffffff !important" } }}></Btn>
          <Btn isExternal={true} link='https://youtu.be/bYu2DEkiDwQ' textColor={"brand.pearl"} width='fit-content' borderColor={"brand.pearl"} bg='brand.space' text='Video ansehen' css={{ path: { stroke: "#ffffff !important" } }}></Btn>
        </Flex>
      </Box>
    </Container>
  );
};

export default EPStage;
