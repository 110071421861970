import React, { useEffect } from "react";
import { useColorMode, Box, Container } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import HeroSection from "../components/HeroSection";
import { Text } from "@chakra-ui/react";
import IconBtn from "../components/IconBtn";
import Layout from "../components/Layout/Layout";
const Contact = () => {
  return (
    <Layout title='Kontakt' colorMode='light'>
      <>
        <Box bg='brand.pearl' w='100%'>
          <Container variant='layoutContainer' pt={{ sm: 24, base: 20 }} px={{ md: 8, base: 4 }}>
            <HeroSection alt='Band Majanko vor einer Hafenmauer' bgColor='brand.sand' justifyContent='center' minH={{ md: "650px", base: "400px" }} maxH={{ md: "650px", base: "400px" }} video={false} mediaOpacity={1} mediaSrc='ep_cover_clean.jpg' parallaxOffsetEnd={0} parallaxOffsetStart={0}></HeroSection>
          </Container>
          <Container variant='layoutContainer' bg='brand.pearl' px={{ md: 8, base: 0 }}>
            <Box px={8} py={16}>
              <Text textStyle='pBig' color='brand.primary' fontFamily='Inter' fontWeight='bold'>
                Kontakt
              </Text>
              <Text as='h2' textStyle='h1' fontFamily='EBGaramond' color='brand.space' mb={4}>
                Ein Stück Weg miteinander gehen.
              </Text>
              <Text textStyle='p' maxW='2xl'>
                Sei es eine Anfrage für einen Auftritt oder Fanpost - wir freuen uns immer auf Nachrichten von Euch! Kontaktiert uns gerne über unsere Social-Media-Kanäle oder schickt uns Elektropost.
                <br></br>
                <br></br>
                Majanko Bauer
                <br></br>
                Postfach:
                <br></br>
                PLZ: 26352
                <br></br>
                Nr: 1218
              </Text>
            </Box>

            <Box display='flex' gap={4} px={8} pb={16}>
              <IconBtn link='tel:015901462820' isExternal={true} w='fit-content' textColor='brand.space' borderColor='brand.space' bg={"transparent"} imgSrc='icon_phone.svg' />
              <IconBtn link='mailto:majanko.music@gmail.com' isExternal={true} w='fit-content' textColor='brand.space' borderColor='brand.space' bg={"transparent"} imgSrc='icon_mail.svg' />
              <IconBtn link='https://instagram.com/majanko.music?igshid=YmMyMTA2M2Y=' isExternal={false} w='fit-content' textColor='brand.space' borderColor='brand.space' bg={"transparent"} imgSrc='icon_insta.svg' />
              <IconBtn link='https://www.facebook.com/majanko.music' isExternal={true} w='fit-content' textColor='brand.space' borderColor='brand.space' bg={"transparent"} imgSrc='icon_fb.svg' />
            </Box>
          </Container>
        </Box>
      </>
    </Layout>
  );
};

export default Contact;
