import { Container, Text } from "@chakra-ui/react";
import React from "react";
import ScheduleEntry from "./ScheduleEntry";

export default function ConcertSchedule() {
  return (
    <Container as='section' variant='layoutContainer' mb={36} mt={36} px={{ md: 8, base: 0 }}>
      <Container variant='layoutContainer' mb={16}>
        <Text textStyle='pBig' color='brand.primary' fontFamily='Inter' fontWeight='bold'>
          Majanko Live erleben
        </Text>
        <Text as='h2' textStyle='h2' fontFamily='EBGaramond' color='brand.space' mb={4}>
          Unsere nächsten Auftritte
        </Text>
        <Text textStyle='p' maxW='2xl'>
          Jeder Auftritt gespielt mit Herz und Seele. Damit du immer als Erste*r erfährst, wann wir wo spielen, folge uns auf Social Media.
        </Text>
      </Container>
      <ScheduleEntry date='22.06.24' city='Streetfood Festival - Wilhelmshaven' isExternal={false} link='/' />
      <ScheduleEntry date='29.06.24' city='Brotway - Wangerooge' isExternal={false} link='/' />
      <ScheduleEntry date='30.06.24' city='Surfschule - Wangerooge' isExternal={false} link='/' />
      <ScheduleEntry date='05.07.24' city='After Work (WADJ) - Wilhelmshaven' isExternal={false} link='/' />
      <ScheduleEntry date='13.07.24' city='Streetfood Festival - Hooksiel' isExternal={false} link='/' />
      <ScheduleEntry date='19.07.24' city='Friesenfestival - Schillig' isExternal={false} link='/' />
      <ScheduleEntry date='31.07.24' city='Mittwochs am Pumpwerk - Wilhelmshaven' isExternal={false} link='/' />
      <ScheduleEntry date='06.08.24' city='Speicher - Carolinsiel (Duo)' isExternal={false} link='/' />
      <ScheduleEntry date='08.08.24' city='Kultursommer - Bramsche' isExternal={false} link='/' />
      <ScheduleEntry date='09.08.24' city='Hotel Lighthouse - Büsum (Trio)' isExternal={false} link='/' />
      <ScheduleEntry date='23.08.24' city='Lokschuppen - Jever' isExternal={false} link='/' />
      <ScheduleEntry date='24.08.24' city='Streetfoodfest - Horumersiel' isExternal={false} link='/' />
      <ScheduleEntry date='14.09.24' city='Dangast - Pricke, 19:30' isExternal={false} link='/' />
      <ScheduleEntry date='20.09.24' city='Beach Motel (Bulli Festival)' isExternal={false} link='/' />
      <ScheduleEntry date='20.09.24' city='Bulli Festival St. Peter Ording Hotel (Trio)' isExternal={false} link='/' />
    </Container>
  );
}
