import { Box, Container, Text } from "@chakra-ui/react";
import React from "react";
import Btn from "./Btn";
import IconBtn from "./IconBtn";
import { Flex } from "@chakra-ui/react";

export default function ContactBanner({ color, topline, heading, text, btnText, btnTextColor, btnBorderColor, btnBgColor, isExternal1, link1, isExternal2, link2, isExternal3, link3, iconBtnColor, iconBtnSrc1, iconBtnSrc2, iconBtnBgColor, iconBtnBorderColor }) {
  return (
    <Container variant='layoutContainer' px={{ md: 8, base: 0 }} py={12}>
      <Box px={8} py={16} color={color}>
        <Text textStyle='pBig' fontFamily='Inter' fontWeight='bold'>
          {topline}
        </Text>
        <Text as='h2' textStyle='h2' fontFamily='EBGaramond' mb={4} maxW='2xl'>
          {heading}
        </Text>
        <Text textStyle='p' maxW='2xl'>
          {text}
        </Text>
        <Flex gap={4} flexWrap='wrap' mt={12}>
          <Btn link={link1} isExternal={isExternal1} w='fit-content' textColor={btnTextColor} text={btnText} fontFamily='EBGaramond' fontWeight='bold' borderColor={btnBorderColor} bg={btnBgColor} />
          <Flex gap={4}>
            <IconBtn link={link2} isExternal={isExternal2} w='fit-content' textColor={iconBtnColor} borderColor={iconBtnBorderColor} bg={iconBtnBgColor} imgSrc={iconBtnSrc1} />
            <IconBtn link={link3} isExternal={isExternal3} w='fit-content' textColor={iconBtnColor} borderColor={iconBtnBorderColor} bg={iconBtnBgColor} imgSrc={iconBtnSrc2} />
          </Flex>
        </Flex>
      </Box>
    </Container>
  );
}
